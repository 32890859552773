.ex1.container {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    min-height: 100%;
}

.ex1-wrap {
    background: #fff;
    border-radius: 8px;
    max-width: 400px;
    padding-right: 57px;
    padding-top: 27px;
    display: flex;
    min-height: 100%;
}

.ex1-field {
    position: relative;
    margin-bottom: 32px;
}


.ex1-fields {
    display: flex;
    margin-left: -16px;
}

.ex1-fields .ex1-field {
    flex: 1;
    margin-left: 16px;
}

.ex1-label {
    display: block;
    text-align: start;
    font-size: var(--fontSizes-md);
    margin-inline-end: var(--space-3);
    margin-bottom: var(--space-2);
    font-weight: var(--fontWeights-medium);
    transition-property: var(--transition-property-common);
    transition-duration: var(--transition-duration-normal);
    opacity: 1;
}

.ex1-input {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    display: flex;
    align-items: center;
    position: relative;
    appearance: none;
    transition: all 0.2s ease 0s;
    font-size: var(--fontSizes-md);
    padding-inline-start: var(--space-4);
    padding-inline-end: var(--space-4);
    height: var(--sizes-10);
    border-radius: var(--radii-md);
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    border-color: inherit;
    background: var(--colors-white);
}

.ex1-input::placeholder {
    color: transparent;
}

.ex1-bar {
    position: relative;
    border-bottom: 0.0625rem solid #999;
    display: block;
}

.ex1-bar::before {
    content: '';
    height: 0.125rem;
    width: 0;
    left: 50%;
    bottom: -0.0625rem;
    position: absolute;
    background: #337ab7;
    -webkit-transition: left 0.28s ease, width 0.28s ease;
    transition: left 0.28s ease, width 0.28s ease;
    z-index: 2;
}

.ex1-button {
    display: inline-flex;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: normal;
    vertical-align: baseline;
    outline: transparent solid 2px;
    outline-offset: 2px;
    width: auto;
    line-height: var(--lineHeights-normal);
    border-radius: var(--radii-none);
    font-weight: var(--fontWeights-medium);
    transition-property: var(--transition-property-common);
    transition-duration: var(--transition-duration-normal);
    height: auto;
    min-width: var(--sizes-10);
    font-size: var(--fontSizes-md);
    padding-inline-start: var(--space-4);
    padding-inline-end: var(--space-4);
    padding: 0px;
    color: var(--colors-secondary-500);
    margin-top: 21px;
    margin-right: 20px;
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.ex1-wrap .token {
    color: #555;
    padding: 10px;
    text-align: center;
    font-weight: 500;
}

.ex1-wrap .error {
    color: #e41029;
    padding: 10px;
    text-align: center;
    font-weight: 500;
}

.ex1-error {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    word-break: break-word;
    color: var(--colors-red-500);
    margin-top: var(--space-2);
    font-size: var(--fontSizes-sm);
    line-height: var(--lineHeights-normal);
}